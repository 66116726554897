import { releaseDetail } from "./release_details";

export const environment = {
  production: true,
  baseUrl: 'https://development.my-masjid.com/api/',
  url: 'https://development.my-masjid.com',
  controlPanelUrl: 'https://controlpaneldev.my-masjid.com/',
  refreshtimeout: '300000',
  version: releaseDetail.version,
  releaseDate: releaseDetail.date,
};